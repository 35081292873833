import { useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { userModelContainer } from '@/store/userModel';
import { track } from '@/utils/track';
import wechat from './assets/wechat.png';
import wechatGroup from './assets/wechat-group-20240511.png';
import wechatImage from './assets/wechat-watermark-remove-20240815.png';
import intfinqWechat from './assets/wechat-intfinq-20240801.jpg';
import WechatPopImage from './assets/wechat-pop-card.png';
import styles from './index.module.less';

const WechatCode = () => {
  const router = useRouter();

  const { displayType } = userModelContainer.useContainer();

  const popoverRef = useRef<HTMLDivElement>(null);

  const info = useMemo(() => {
    const defaultInfo = {
      slide: '官方社群',
      title: 'TextIn 用户交流群',
      code: wechatGroup,
      desc: '微信扫码加入官方用户交流群',
      tags: [
        ['产品咨询', '问题沟通'],
        ['使用交流', '活动优惠'],
      ],
      defaultVisible: false,
    };
    if (router.query.service) {
      const serviceMap: Record<string, any> = {
        // 图像处理类
        3: {
          slide: '社群交流',
          title: 'TextIn-AI图像处理',
          code: wechatImage,
          tags: [
            ['福利发放', '折扣领取'],
            ['使用交流', '问题沟通'],
          ],
          defaultVisible: true,
        },
      };
      const curPath = router.asPath.split('?')[0];
      const paths = [
        '/market/detail/watermark-remove',
        '/market/detail/crop_enhance_image',
        '/market/detail/dewarp',
        '/experience/text_auto_removal',
        '/experience/demoire',
        '/experience/image_quality_inspect',
      ];
      // 只替换这些页面
      if (paths.includes(curPath)) {
        Object.assign(defaultInfo, serviceMap[displayType]);
      }
      // if (serviceMap[displayType]) {
      //   Object.assign(defaultInfo, serviceMap[displayType]);
      // }
    }
    if (router.route === '/product/textin_intfinq') {
      Object.assign(defaultInfo, {
        title: 'TextIn IntFinQ 用户交流群',
        code: intfinqWechat,
      });
    }
    return defaultInfo;
  }, [router, displayType]);

  const [visible, setVisible] = useState(info.defaultVisible);
  const [showApiDocTips, setShowApiDocTips] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
    if (!visible) {
      setShowApiDocTips(false);
    }
    if (visible) {
      track({ name: '内容曝光', keyword: `侧边栏【官方社群】` });
    }
  };

  const apiDocTipsTimer = useRef<any>(null);

  const removeApiDocTipsTimer = () => {
    if (apiDocTipsTimer.current) {
      clearTimeout(apiDocTipsTimer.current);
    }
  };
  const addApiDocTipsTimer = () => {
    apiDocTipsTimer.current = setTimeout(() => {
      const lastTipsTime = localStorage.getItem('lastApiDocTipsTime');
      if (lastTipsTime && dayjs().diff(lastTipsTime, 'day') < 1) {
        return;
      }
      setVisible(true);
      setShowApiDocTips(true);
      setTimeout(() => {
        setVisible(false);
        setShowApiDocTips(false);
      }, 15000);
      localStorage.setItem('lastApiDocTipsTime', dayjs().toJSON());
      track({
        name: '内容曝光',
        keyword: '社群交流_主动弹出',
      });
    }, 30000);
  };
  useEffect(() => {
    const showApiDocTipsRoutes = ['/document/pdf_to_markdown'];
    if (showApiDocTipsRoutes.includes(router.asPath)) {
      removeApiDocTipsTimer();
      addApiDocTipsTimer();
    } else {
      removeApiDocTipsTimer();
    }
  }, [router]);

  const wechatCodePopover = (
    <div className={styles.wechatCode}>
      {(info.defaultVisible || showApiDocTips) && (
        <CloseOutlined
          className={styles.close}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            popoverRef.current?.click();
          }}
        />
      )}
      <div className={styles.wechatCodeTitle}>{info.title}</div>
      <div className={styles.wechatCodeImg}>
        <Image src={info.code} width={132} height={132} alt="" />
      </div>
      <div className={styles.wechatCodeDesc}>{info.desc}</div>
      {Array.isArray(info.tags) &&
        info.tags.map((row, rowIdx) => (
          <div className={styles.wechatCodeTag} key={rowIdx}>
            {Array.isArray(row) &&
              row.map((tag) => (
                <div key={tag}>
                  <span>{tag}</span>
                </div>
              ))}
          </div>
        ))}
      {showApiDocTips && (
        <div className={styles.apiDocTipsPopover}>
          <Image src={WechatPopImage} alt="" />
          <div className={styles.apiDocTipsText}>
            <div>API调用有问题？ </div>
            <div>加入社群，专家即时解答！ </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Popover
      placement="leftBottom"
      content={wechatCodePopover}
      overlayClassName={styles.wechatCodePopover}
      defaultVisible={info.defaultVisible}
      visible={visible}
      key={info.title}
      getPopupContainer={() => document.querySelector('#consult-slide') || document.body}
      trigger={['hover', 'click']}
      onVisibleChange={handleVisibleChange}
    >
      <div className={styles.wechat} ref={popoverRef}>
        <div className={styles.icon}>
          <Image src={wechat} width={24} height={24} alt="" />
        </div>
        <div className={styles.textWrapper}>
          <span>{info.slide}</span>
        </div>
      </div>
    </Popover>
  );
};

export const WechatCodeSlide = (props) => (
  <div className={styles['wechat-slide']}>
    <WechatCode {...props} />
  </div>
);

export default WechatCode;
